<template>
  <DefaultLayout
    title="闪现一下"
    class="default-layout-reservation"
  >
    <NewGameLoading
      v-if="pageLoading"
    />
    <PostError
      v-else-if="postError"
      :custom-header="true"
      :text="'系统繁忙'"
      :status="'fail'"
    />
    <div
      v-else
      class="page-game-reservation"
    >
      <GroupOrGameHeader
        v-if="isApp"
        :is-from-group="false"
        :show-new-game-header="true"
        :menu-status="false"
        title-content="新游早知道"
        class="page-game-reservation-header"
      />
      <template
        v-if="Array.isArray(rcmdGame) && rcmdGame.length"
      >
        <div class="page-game-reservation-title">
          小编精选
        </div>

        <!-- 轮播图 -->
        <div class="page-game-reservation-swiper">
          <swiper
            :options="gameSwiperOption"
          >
            <swiper-slide
              v-for="(item, index) in rcmdGame"
              :key="index"
            >
              <div
                :style="{height:`${height}px`}"
                class="page-game-reservation-swiper-slide"
              >
                <ReservationItem
                  :rcmd-game="item"
                  :swiper-slide-index="index"
                  :real-index="realIndex"
                />
              </div>
            </swiper-slide>
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
        </div>
      </template>
      <template v-if="Array.isArray(hotContents) && hotContents.length">
        <div class="page-game-reservation-title">
          热门资讯
        </div>
        <ul class="page-game-reservation-hot">
          <li
            v-for="(item,index) in hotContents"
            :key="index"
            class="page-game-reservation-hot-item"
          >
            <NewGameHot :hot-info="item" />
          </li>
        </ul>
      </template>
      <div class="page-game-reservation-title">
        所有精彩游戏
      </div>
      <template
        v-if="Array.isArray(allGames) && allGames.length"
      >
        <ul
          v-for="(item,index) in allGames"
          :key="index"
          class="page-game-reservation-list"
        >
          <li class="page-game-reservation-game">
            <NewGameInfo
              :game-info="item"
              :is-show-hot-info="true"
            />
          </li>
        </ul>
      </template>
      <div
        v-else
        class="page-game-reservation-game-empty"
      >
        <div class="page-game-reservation-game-empty-img" />
        <div class="page-game-reservation-game-empty-text">
          更多新游正在路上，尽请期待～
        </div>
      </div>

      <GameSubscribeDialog
        v-model="dialogShow"
      />
    </div>
  </DefaultLayout>
</template>
<script>
import { mapState } from 'vuex';
import 'swiper/dist/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import DefaultLayout from '@/layouts/default/index.vue';
import NewGameLoading from '@/component/new-game-loading/index.vue';
import ReservationItem from './component/reservation-item/index.vue';
import GroupOrGameHeader from '@/component/group-or-game-header/index.vue';
import NewGameInfo from '@/component/new-game-info/index.vue';
import GameSubscribeDialog from '@/component/game-subscribe-dialog/index.vue';
import PostError from '@/component/post-error/index.vue';
import NewGameHot from '@/component/new-game-hot/index.vue';
import newGameApi from '@/api/newgame';
import reportApi from '@/api/report';
import { receiveGlobalNotification, openView } from '@/jsbridge';

export default {
  name: 'GameReservation',
  components: {
    DefaultLayout,
    NewGameLoading,
    ReservationItem,
    Swiper,
    SwiperSlide,
    GroupOrGameHeader,
    NewGameInfo,
    GameSubscribeDialog,
    PostError,
    NewGameHot,
  },
  data() {
    const vueThis = this;
    return {
      realIndex: undefined,
      gameSwiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          activeIndexChange() {
            // 通过this.realIndex 获取当前轮播图的下标
            vueThis.realIndex = this.realIndex;
          },
        },
      },
      pageLoading: false,
      allGames: [],
      rcmdGame: [],
      hotContents: [],
      dialogShow: false,
      postError: false,
      height: 0,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
    }),
  },
  mounted() {
    this.pageLoading = true;
    const width = document.documentElement.clientWidth;
    // 让swiper的pagination在内容下方 整个高度 > 视频组件+ 想玩组件
    this.height = (width - 36) * 380 / 678 + 106;
    this.$store.dispatch('user/getUserInfo');
    reportApi({
      pageid: '2901',
      moduleid: 1,
      eventtype: 2,
      extendid: 1,
    }, '新游预约页曝光');
  },
  activated() {
    this.getNewGameList();
    this.registerEvent();
  },
  provide() {
    return {
      onIsWantHandler: (...arg) => this.onIsWantHandler(...arg),
      dialogShowHandler: (...arg) => this.dialogShowHandler(...arg),
    };
  },
  methods: {
    dialogShowHandler(isShow) {
      this.dialogShow = isShow;
    },
    getNewGameList() {
      newGameApi.getNewGameList().then((res) => {
        // 返回有视频 且是上架的
        // eslint-disable-next-line max-len
        const rcmdGame = res.rcmdGame?.filter((item) => {
          const hasVideo = item.subscribeInfo.banners.some(banner => banner.bannerType === 2 && banner.videoUrl);
          return item.gameInfo && hasVideo;
        });
        this.rcmdGame = rcmdGame;
        this.allGames = res.allGames.filter(item => item.gameInfo);
        this.hotContents = res.hotContents.slice(0, 3);
        this.pageLoading = false;
      })
        .catch((err) => {
          this.postError = true;
          this.pageLoading = false;
          this.logger.err('getNewGameList err', err);
        });
    },
    // 监听成功加入圈子事件 刷新页面
    registerEvent() {
      receiveGlobalNotification('g_join_group_id', () => {
        this.getNewGameList();
      });
      receiveGlobalNotification('g_quit_group_id', () => {
        this.getNewGameList();
      });
      receiveGlobalNotification('g_subscribe_stats', () => {
        this.getNewGameList();
      });
    },
    onIsWantHandler() {
      // allGames 和rcmdGame 有重复游戏 '想玩' 状态要一致,重新获取数据
      this.getNewGameList();
    },
    onGoPostPage(url) {
      openView({
        options: url,
      });
    },
  },
};
</script>
<style scoped lang="scss" src="./scss/index.scss"></style>
