<template>
  <div class="container">
    <div
      :style="{height:`${height}px`}"
      class="container-top"
    >
      <img
        :src="replaceHttps(bannerInfo.coverImg)"
        alt=""
        class="container-top-img"
      >
      <div
        @click="onVideoPlayHandler"
        class="container-top-play"
      />
    </div>

    <div
      v-if="videoShow"
      :style="{height:`${height}px`}"
      class="container-video"
    >
      <TXCloudPlayer
        ref="videoRef"
        :banner-info="bannerInfo"
        :real-index="0"
        :swiperSlideIndex="1"
        :autoplay="true"
        :muted="false"
        :current-time-last="currentTimeLast"
        @setCurrentTimeLast="setCurrentTimeLast"
        class="container-video-show"
      />
    </div>

    <!-- 想玩组件 -->
    <NewGameInfo
      :game-info="rcmdGame"
      class="container-bottom"
    />
  </div>
</template>
<script>
import TXCloudPlayer from '@/component/tx-cloud-player/index.vue';
import NewGameInfo from '@/component/new-game-info/index.vue';
export default {
  name: 'ReservationItem',
  components: {
    TXCloudPlayer,
    NewGameInfo,
  },
  props: {
    swiperSlideIndex: {
      type: Number,
      require: true,
      default: -1,
    },
    realIndex: {
      type: Number,
      require: true,
      default: -1,
    },
    rcmdGame: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bannerInfo: {},
      videoShow: false,
      currentTimeLast: 0,
      height: 0,
    };
  },
  watch: {
    realIndex() {
      if (this.realIndex !== this.swiperSlideIndex) {
        this.videoShow = false;
      }
    },
  },
  created() {
    this.bannerInfo = this.findVideo(this.rcmdGame.subscribeInfo.banners);
  },
  mounted() {
    const width = document.documentElement.clientWidth;
    this.height = (width - 36) * 380 / 678;
  },
  methods: {
    findVideo(banners) {
      const video = banners.find(item => item.bannerType === 2);
      if (video) {
        return video;
      }
      return banners.find(item => item.bannerType === 1);
    },
    onVideoPlayHandler() {
      this.videoShow = true;
    },
    setCurrentTimeLast(currentTimeLast) {
      this.currentTimeLast = currentTimeLast;
    },
    replaceHttps(url) {
      return url.replace('http://', 'https://');
    },
  },
};
</script>
<style  lang="scss" scoped src="./scss/index.scss"></style>
